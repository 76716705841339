import { mapAzuresearchResultsToProduct } from '~/lib/util/azuresearch'

export default {
  setProducts(state, products) {
    const newProducts = products.map(product => {
      return mapAzuresearchResultsToProduct(product)
    })

    state.products = newProducts
  },

  setCategories(state, categories) {
    state.categories = [...categories]
  },

  setTitle(state, { title }) {
    state.title = title
  },

  setQuery(state, payload) {
    state.query = { ...payload }
  },
  setCount(state, value) {
    state.count = value
  },
  setActiveFilter(state, value) {
    state.activeFilter = value
  }
}
