import _find from 'lodash/find'
import { isMaxQty } from '~/lib/util/basket'
import { callAWSFrontendExtrasAPI } from '~/lib/util/aws'

const jwt = require('jwt-simple')

export const state = () => ({})

export const getters = {}

export const mutations = {
  ADD_BREADCRUMBS(state, { path, magazine, page, breadcrumbs }) {
    // console.log('ADD_BREADCRUMBS')
    // console.log('path=', path)
    // console.log('magazine=', magazine)
    // console.log('page=', page)
    // console.log('breadcrumbs=', breadcrumbs)

    let newBreadcrumbs = [
      {
        first: true,
        value: 'magazine subscriptions',
        url: '/',
        last: false
      }
    ]
    if (page) {
      newBreadcrumbs = [
        {
          first: true,
          value: 'magazine subscriptions',
          url: '/',
          last: false
        },
        {
          first: false,
          value: page.value,
          url: page.type,
          last: true
        }
      ]
    } else if (magazine) {
      // trim the extra / from end to start and the magazines placeholder
      let newPath = path.replace(/\/$/, '')

      if (newPath.includes('/magazines-')) {
        // USE CASE 1: breadcrumbs path like /magazines-with-free-gifts/country-living-magazine
        let t = 0
        newPath = newPath.replace(/magazines/g, match => (++t === 2 ? ' ' : match))
      } else {
        // USE CASE 2: other breadcrumbs path
        newPath = newPath.replace(/^\/magazines/, '')
      }
      newPath = newPath.replace(/^\//, '')
      const breadcrumbs = newPath.split('/').filter(item => {
        return item !== undefined
      })
      newBreadcrumbs = [{ first: true, last: false, url: '/', value: 'magazine subscriptions' }]

      // console.log('newBreadcrumbs=', newBreadcrumbs)
      if (breadcrumbs.length >= 2) {
        for (let index = 0; index < breadcrumbs.length; index++) {
          if (index === breadcrumbs.length - 1) {
            // const key = 'id'
            newBreadcrumbs[index + 1] = {
              value: (magazine.h1Title || magazine.title).replace(/-/g, ' '),
              url: magazine.url,
              last: true
            }
          } else if (index === 0) {
            // const key = 'category'
            const url = `/${breadcrumbs[index]}`
            let value = breadcrumbs[index].replace(/magazine-subscriptions-/g, '')
            value = value.replace(/magazine-subscription-/g, '')
            value = value.replace(/-magazine-subscriptions/g, '')
            value = value.replace(/-magazines/g, '')
            value = value.replace(/-/g, ' ')
            value = value.replace(/ and /g, ' & ')
            newBreadcrumbs[index + 1] = {
              value,
              url,
              last: false
            }
          } else if (index === 1) {
            // const key = 'subCategory'
            const url = `/${breadcrumbs[0]}/${breadcrumbs[index]}`
            let value = breadcrumbs[index].replace(/magazine-subscriptions-/g, ' ')
            value = value.replace(/magazine-subscription-/g, '')
            value = value.replace(/-magazine-subscriptions/g, '')
            value = value.replace(/-magazines/g, '')
            value = value.replace(/-/g, ' ')
            value = value.replace(/ and /g, ' & ')
            newBreadcrumbs[index + 1] = {
              value,
              url,
              last: false
            }
          }
        }
      } else if (magazine.breadcrumbCatName === '' || magazine.breadcrumbCatName === null) {
        newBreadcrumbs = [
          {
            first: true,
            value: 'magazine subscriptions',
            url: '/',
            last: false
          },
          {
            first: false,
            value: (magazine.h1Title || magazine.title).replace(/-/g, ' '),
            url: magazine.url,
            last: true
          }
        ]
      } else {
        newBreadcrumbs = [
          {
            first: true,
            value: 'magazine subscriptions',
            url: '/',
            last: false
          },
          {
            first: false,
            value: magazine.breadcrumbCatName,
            url: magazine.breadcrumbCatUrl,
            last: false
          },
          {
            first: false,
            value: (magazine.h1Title || magazine.title).replace(/-/g, ' '),
            url: magazine.url,
            last: true
          }
        ]
      }
    } else if (breadcrumbs) {
      newBreadcrumbs = [...breadcrumbs]
    }
    console.log('newBreadcrumbs FINAL', newBreadcrumbs)
    state.UI.breadcrumbs = newBreadcrumbs
  },

  addToCart(state, item) {
    console.log('addToCart productId=' + item.offer.productId)
    // Inc +1 to the selected item
    if (item.offer.productId) {
      const a = _find(state.basket.items, function(i) {
        return i.offer.productId === item.offer.productId
      })
      if (a === undefined) {
        console.log('addToCart PUSH')
        const itemToAdd = item
        itemToAdd.offer.qty = 1
        state.basket.items.push(itemToAdd)
      } else {
        console.log('addToCart ADD QTY')
        a.offer.qty++
      }
    }
  }
}
export const actions = {
  SAVE_ISMOBILE({ commit }, value) {
    console.log(`SAVE_ISMOBILE value=${value}`)
    commit('UI/isMobile', value)
  },

  LOAD_ACTIVE_BASKET({ commit, state }, params) {
    try {
      const cartId = params.cartId
      console.log(`LOAD_ACTIVE_BASKET cartId=${cartId}`)

      const currentTime = new Date().getTime()

      const response = { result: { items: [], numOfItems: 0, total: 0, cartId: undefined, updatedAt: currentTime } }

      // Call Magento Cart API
      if (cartId) {
        // Get the Magento cart if the cartId has been defined

        console.log('LOAD_ACTIVE_BASKET Backend -> Magento')

        // Calling the Frontend Api for the Basket (we have axios-cache LRU in place)
        process.env.NODE_ENV !== 'production' && require('axios-debug-log')
        const endpoint = `${this.$config.backendURL}/backendapi/cart/pull/${jwt.encode({ cartId }, process.env.JWT_SECRET)}`
        this.$axios
          .get(endpoint, {
            headers: {
              'Content-Type': 'application/json',
              'Cache-Control': 'max-age=0'
            },
            timeout: 40000,
            useCache: false
          })
          .then(response => {
            console.log('FRONTEND RAW RESPONSE=', response)
            const res = response?.data?.result
            console.log('FRONTEND RESULT=', res)
            // Call Frontend API and update the Basket
            if (res && res !== 'ERRORS') {
              // Add a updatedAt field
              res.updatedAt = currentTime
              console.log('LOAD_ACTIVE_BASKET Response payload=', res)
              commit('basket/payload', res)
              res.upsell && commit('basket/upsell', res.upsell)
              commit('basket/aggregateTotals', res)
              params.context.$nuxt.$emit('BASKET_READY', { ready: true, basket: res })
            } else {
              console.log('LOAD_ACTIVE_BASKET Remove Cookies')
              this.$cookies && this.$cookies.remove('cart')
              commit('basket/id', undefined)
              commit('basket/payload', undefined)
              commit('basket/aggregateTotals', undefined)
            }
          })
          .catch(e => {
            console.log('LOAD_ACTIVE_BASKET AXIOS ERROR=', e)
          })
      } else if (!cartId) {
        // Default empyt session if the customer never add anything on the cart
        console.log('LOAD_ACTIVE_BASKET DEFAULT Empty SESSION')
        commit('basket/id', undefined)
        commit('basket/payload', response.result)
        commit('basket/aggregateTotals', response.result)
      }

      return response
    } catch (error) {
      return { error: { message: error.message, code: error.code } }
    }
  },

  async UPDATE_CART({ dispatch, commit, state }, params) {
    try {
      const cartId = params.cartId
      console.log(`UPDATE_CART action=${params.action} cartId=${cartId} productId=${params.productId}`)
      // Check if the qty has reached the max allowed
      if (params.action === 'add') {
        if (params.item.offer.productId) {
          const a = _find(state.basket.items, function(i) {
            return i.offer.productId === params.item.offer.productId
          })
          console.log('Check isMaxQty')
          console.log(a)
          if (a !== undefined && a.offer && isMaxQty(a)) {
            // TODO centralise Error messaging
            const error = new Error(`You reached the maximum quantity allowed for the subscription ${params.item.title}`)
            error.code = 'MAX_QTY_REACHED'
            throw error
          }
        }
      }
    } catch (error) {
      console.log(error.message)
      return { error: { message: error.message, code: error.code } }
    }

    try {
      const cartId = params.cartId
      if (cartId === undefined) {
        // Create a new cart
        console.log('UPDATE_CART action=create Backend -> Magento')
        let response = await this.$magentoCartAPI.create()
        let cartId
        if (response.result) {
          // Update CartId and create and empty Cart from the API response
          cartId = response.result.cartId
          commit('basket/id', response.result.cartId)
          // window.$cookies.set(jwt.encode({ cartId: cartId }))
        }

        // Call Magento Cart API
        console.log(`UPDATE_CART action=${params.action} Backend -> Magento`)
        console.log(jwt.encode({ cartId, productId: params.productId, action: params.action }, process.env.JWT_SECRET))
        response = await this.$magentoCartAPI.update(jwt.encode({ cartId, productId: params.productId, action: params.action, item: params.item }, process.env.JWT_SECRET))
        console.log()
        commit('basket/payload', response.result)

        // Create the new Basket Totals
        await dispatch('AGGREGATE_BASKET_TOTALS')

        return true
      }

      // Remove/Update/Add an offer from/to the cart
      if (params.productId && params.action) {
        const cartId = params.cartId
        // Call Magento Cart API
        console.log(`UPDATE_CART action=${params.action} Backend -> Magento`)
        console.log(jwt.encode({ cartId, productId: params.productId, action: params.action }, process.env.JWT_SECRET))
        const response = await this.$magentoCartAPI.update(jwt.encode({ cartId, productId: params.productId, action: params.action, item: params.item }, process.env.JWT_SECRET))

        console.log(`UPDATE_CART action=${params.action} Backend -> Magento response=`, response)
        // Update the cart items
        switch (params.action) {
          case 'create':
            commit('basket/payload', params.item)
            break
          case 'add':
            if (response.result.items) {
              commit('basket/payload', response.result)
            }
            break
          case 'remove':
            if (response.result.items) {
              commit('basket/payload', response.result)
            }
            break
          case 'delete':
            if (response.result.items) {
              commit('basket/payload', response.result)
            }
            break
          case 'update':
            if (response.result.items) {
              commit('basket/payload', response.result)
            }
            break
          default:
            // refresh
            return true
        }

        // Update the Upsell data
        response.result.upsell && commit('basket/upsell', response.result.upsell)

        // Create the new Basket Totals
        await dispatch('AGGREGATE_BASKET_TOTALS')
      }
      return {}
    } catch (error) {
      console.log('UPDATE_CART error', error)
      return { error: { message: error.message, code: error.code } }
    }
  },

  AGGREGATE_BASKET_TOTALS({ commit, state }) {
    try {
      console.log(`AGGREGATE_BASKET_TOTALS state.basket.id=${state.basket.id}`)

      // Update the Basket's Totals
      if (state.basket.id) {
        commit('basket/aggregateTotals', state.basket)
      }

      return {}
    } catch (error) {
      return { error: { message: error.message, code: error.code } }
    }
  },

  // WA https://github.com/nuxt/nuxt.js/issues/3259
  async nuxtServerInit({ commit }, { route, $config, query, $axios, $ua }) {
    // Fill the debug flag
    commit('UI/debug', $config.debug || route?.query?.JMAGDebug === 'true')

    // Fill the useCache flag
    let useCache = $config.AWSAPIGatewayUseCache
    if (route?.query?.useCache) {
      useCache = route?.query?.useCache === 'true'
    }
    commit('UI/useCache', useCache)

    // Fill the Status Code
    commit('UI/statusCode', 200)

    console.log('nuxtServerInit query=', query)
    console.log('nuxtServerInit QS useCache=', useCache)
    // console.log('nuxtServerInit $ua=', $ua)

    const { sitelinks, sitelinksmobile, sitewide, sitewidemobile, bestsellers, timestamp } = await callAWSFrontendExtrasAPI({
      context: { route, $config, query, $axios },
      useCache
    })

    // console.info('nuxtServerInit sitelinks=', sitelinks)
    // console.info('nuxtServerInit sitelinksmobile=', sitelinks)
    // console.info('nuxtServerInit sitewide=', sitewide)
    // console.info('nuxtServerInit sitewidemobile=', sitewidemobile)
    // console.info('nuxtServerInit bestsellers=', bestsellers)
    // console.info('nuxtServerInit timestamp=', timestamp)

    sitelinks && commit('utility/sitelinks', sitelinks, { root: true })
    sitelinksmobile && commit('utility/sitelinksmobile', sitelinksmobile, { root: true })
    sitewide && commit('utility/sitewide', sitewide, { root: true })
    sitewidemobile && commit('utility/sitewidemobile', sitewidemobile, { root: true })
    bestsellers && commit('utility/bestsellers', bestsellers, { root: true })
    timestamp && commit('utility/timestamp', timestamp, { root: true })
  }
}
